(function($) {

  var methods = {
    init: function(options) {

      return this.each( function() {

        // options
        var defaults = {
          debug: false,     // whether to log debug messages
          target: false,
          url: false,
          iframe: false,
        }
        var $this = $(this);
        var opts = $.extend(defaults, options, $this.data());

        // error checking
        if (!opts.target) {
          error_message('[jQuery.markdown] A target is required');
          return false;
        }

        opts.target = $(opts.target);

        // store data
        $this.data('markdown',opts);

        //$this.height( $(window).height() - 20 );
        //opts.target.height( $(window).height() - 20 );

        $this.keyup( function() { methods['parse'].call($this) } ).keyup();

        return this;

      } );

    },

    parse: function(element) {
      var $this = this;
      var opts = $this.data('markdown');

      var options = {
        url: opts.url,
        dataType: 'html',
        data: {
          input: $this.val()
        },
        success: _update_target,
        type: 'post',
        error: log_errors
      }
      $.ajax(options);

      function _update_target(data) {

        let width = parseInt(opts.target.innerWidth()) - 20;
        let height = parseInt(opts.target.innerHeight()) - 20;

        let output = data;
        if (opts.iframe) {
          output = $('<iframe width="' + width + '" height="' + height + '"></iframe>');
          opts.target.html(output);
          output.contents()[0].write(data);
        } else {
          opts.target.html(output);
        }

        if (opts.source)
          opts.source.html(nl2br(escapeHtml(output)));
      }
    },

  }

  $.fn.markdown = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.markdown' );
    }

  }

})(jQuery);
