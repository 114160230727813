var hostname = location.host;
var base_url = '//' + hostname + '/';

jQuery.extend({
  check_session: function() {

    function check_session_handler(data) {
      //console.log('[check_session.check_session_handler]', data);

      if (data.session == 'expired') {
        var options = {
          dataType: 'json',
          type: 'post',
          url: base_url + 'messages/add_message',
          data: {
            message: 'Your session has expired and you have been logged out.'
          }
        }
        $.ajax(options);
        if (location.pathname != '/login')
          location.href = data.redirect + '?redirect=' + location.href;
      }
    }

    if (location.pathname != '/login') {
      //console.log('[check_session] checking session');

      var options = {
        dataType: 'json',
        type: 'post',
        url: base_url + 'login/check_session',
        success: check_session_handler,
        error: log_errors
      }
      $.ajax(options);
    }

  }

});

// -----------------------------------------------------------------------
// NOTIFICATIONS
// -----------------------------------------------------------------------

function message(text, type = 'info', delay = 5000) {

  var opts = {
    type: type,
    delay: delay,
    element: '.notifications',
    align: 'center',
    width: '80%'
  }

  if (typeof text == 'array') {
    for (var i=0; text[i]; i++)
      $.notify(text[i], opts);
  } else {
    $.notify(text,opts);
  }
};

function error_message(text) {
//  console.log('[error_message]', text, Array.isArray(text));
  var opts = {
    type: 'danger',
    delay: 10000,
    element: '.notifications',
    align: 'center',
    width: '80%'
  }

  if (Array.isArray(text)) {
    for (var i=0; text[i]; i++)
      $.notify(text[i], opts);
  } else {
    $.notify(text,opts);
  }
};

function log_errors(xhr, status, text) {

  console.log('Status: ' + status + ' Response: ' + text + ' (Request object follows.)');
  if (xhr.responseText)
    console.log(xhr.responseText);
  console.log(xhr);

  if ( status == 'error' && text == '' && xhr.responseText == '' ) {
    //for (var i in xhr)
    //console.log(xhr[i]);
    return;
  }

  var error_message_str = 'Error: Unable to complete your request.';
  if (xhr.responseJSON && xhr.responseJSON.error) {
    error_message_str = xhr.responseJSON.error;
  } else if (status == 'error' && xhr.responseText !== '') {
    var matches = xhr.responseText.match(/<div id="container">\s+<h1>An Error Was Encountered<\/h1>\s+<p>([\s\S]+)<\/p>/);
    if (matches && matches[1])
      error_message_str = matches[1];
  }

  error_message(error_message_str);

  if (xhr.status == 422) {
    // explicit error
    return;
  }

  /* the following is only if we're e-mailing errors...

  var error_message = 'Status: ' + status + ' Response: ' + text + ' (Request object follows.)';
  error_message += xhr.responseText;
  error_message += xhr;

  // traceback data
  var data = {};
  if (this.data)
    data = this.data;

  var called_by = 'unknown';
  if (this.url)
    called_by = this.url;

  // ajax
  var options = {
    url: base_url + 'error_page/email_errors',
    data: {
      url: location.href,
      error_message: error_message,
      called_by: called_by,
      data: data
    },
  }
  console.log(options.data);

  var email = false;
  if (email) {
    options = $.extend({}, ajax_options, options);
    $.ajax(options);
  }
  */

}

var ajax_options = {
  dataType: 'json',
  type: 'post',
  error: log_errors
}

function check_messages() {
  var options = {
    url: base_url + 'messages',
    success: _display_messages
  }
  options = $.extend({}, ajax_options, options);
  $.ajax(options);

  function _display_messages(messages) {
    if (messages) {
      for (var i=0; messages[i]; i++) {
        message(messages[i]);
      }
    }
  }
}

function check_error_messages() {
  var options = {
    url: base_url + 'messages/error',
    success: _display_messages
  }
  options = $.extend({}, ajax_options, options);
  $.ajax(options);

  function _display_messages(messages) {
    if (messages) {
      for (var i=0; messages[i]; i++) {
        error_message(messages[i]);
      }
    }
  }
}


// -----------------------------------------------------------------------
// STRINGS
// -----------------------------------------------------------------------

jQuery.extend( {
  basename: function(path) {
    return path.replace(/\\/g,'/').replace( /.*\//, '' );
  }
} );

function format_check_cancel(val) {
  var html = '';

  var set = 'fa';
  if (arguments.length > 1)
    set = arguments[1];

  if ( val == 't' || val == 'Yes' || val === true )
    html = '<span class="' + set + ' ' + set + '-check ' + set + '-green"></span>';
  else
    html = '<span class="' + set + ' ' + set + '-cancel ' + set + '-times ' + set + '-red"></span>';

  return html;
}

function array2csl(array) {

  var sub_key = false;
  if (arguments.length > 1)
    var sub_key = arguments[1];

  var new_array = [];
  if (sub_key) {
    for (var i in array) {
      if (array[i][sub_key])
        new_array.push(array[i][sub_key]);
    }
  } else {
    new_array = array;
  }

  if (new_array.length == 1) {
    return new_array[0];
  }

  var output = new_array.join(', ');
  var index = output.lastIndexOf(',');
  output = output.substring(0,index) + ' and' + output.substring(index+1);

  return output;
}

function serializeArrayToObject(form_data) {
  var data = {};
  for (var i=0; form_data[i]; i++) {
    var row = form_data[i];
    var name = row.name;

    if (row.name.indexOf('[]') > -1) {
      name = name.replace('[','').replace(']','');
      if (!data[name])
        data[name] = [];

      data[ name ].push( row.value );
    } else {
      data[ name ] = row.value;
    }
  }

  return data;
}


// animated remove
jQuery.fn.extend({
  fadeOutRemove: function() {
    var $this = this;

    var speed = 'normal';
    if (arguments.length > 0)
      speed = arguments[0];

    var afterRemove = function() {};
    if (arguments.length > 1)
      afterRemove = arguments[1];

    $this.fadeOut(speed, function() {
      let table = $(this).parents('table');
      $(this).remove();
      if (table.length)
        table.trigger('applyWidgets');
      afterRemove.call();
    });
  },

  slideUpRemove: function() {
    var $this = this;

    var speed = 'normal';
    if (arguments.length > 0)
      speed = arguments[0];

    var afterRemove = function() {};
    if (arguments.length > 1)
      afterRemove = arguments[1];

    $this.slideUp(speed, function() { $(this).remove(); afterRemove.call(); });
  },
});
