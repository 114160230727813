(function($) {

  var methods = {
    init: function(options) {

      return this.each( function() {

        // options
        var defaults = {
          debug: true, //false,     // whether to log debug messages
          deleteUrl: false,
          hideWithInput: false,
          hideWithSpan: false
        }
        var $this = $(this);

        options.file = $this.attr('value');
        options.field = $this.attr('name');

        options.file_span = $('<div class="file-name"></div>').insertBefore($this);
        options.file_name = $('<span class="mr-2">' + $.basename(options.file)  + '</span>').appendTo(options.file_span);
        options.button = $('<a href="javascript:void(0);" class="btn btn-outline-primary" title="Delete file"><i class="fa fa-trash"></i></a>').appendTo(options.file_span);
        options.hidden = $('<input type="hidden" name="' + options.field + '" value="' + options.file + '">').insertBefore($this);

        var opts = $.extend(defaults, options, $this.data());

      // error checking
        if (!opts.deleteUrl) {
          error_message('[jQuery.fileInput] A deleteUrl is required');
          return false;
        }

        // store data
        $this.data('fileInput',opts);

        if ( options.file == '' )
          methods['showFileInput'].call($this);
        else
          methods['hideFileInput'].call($this);

        options.button.click( function() { methods['deleteFile'].call($this) } );

      } );

    },

    deleteFile: function() {

      var $this = this;
      var opts = $this.data('fileInput');

      var data = $.extend({},$this.data());
      delete data.fileInput;
      data.filename = opts.file;
      data.field = opts.field;

      if (opts.debug)
        console.log('[deleteFile]', 'data:', data, 'opts:',opts);

      var options = {
        url: opts.deleteUrl,
        success: _deleteFile,
        data: data
      }
      options = $.extend({}, ajax_options, options);

      if (opts.debug)
        console.log('[deleteFile]','options:',options);

      $.ajax(options);

      function _deleteFile(data) {
        if (opts.debug)
          console.log('[_deleteFile]',data);
        methods['showFileInput'].call($this);
      }

    },

    showFileInput: function() {
      var $this = this;
      var opts = $this.data('fileInput');

      if (opts.debug)
        console.log('[showFileInput]', opts);

      opts.file_span.addClass('d-none');
      opts.hidden.val('');
      opts.file_name.text('');
      $this.removeClass('d-none').val('');

      if (opts.showWithInput) {
        $(opts.showWithInput).removeClass('d-none');
      }
      if (opts.showWithSpan) {
        $(opts.showWithSpan).addClass('d-none');
      }
    },

    hideFileInput: function() {
      var $this = this;
      var opts = $this.data('fileInput');

      if (opts.debug)
        console.log('[hideFileInput]', opts);

      opts.file_span.removeClass('d-none');
      opts.hidden.val(opts.file);
      opts.file_name.text( $.basename(opts.file) );
      $this.addClass('d-none').val('');

      if (opts.showWithInput) {
        $(opts.showWithInput).addClass('d-none');
      }
      if (opts.showWithSpan) {
        $(opts.showWithSpan).removeClass('d-none');
      }
    },

    setFile: function(file_name) {
      var $this = this;
      var opts = $this.data('fileInput');

      opts.file = file_name;
      $this.data('fileInput', opts);
    }

  }

  $.fn.fileInput = function(method) {

    if ( methods[method] ) {
      return methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ) );
    } else if ( typeof method === 'object' || ! method ) {
      return methods.init.apply( this, arguments );
    } else {
      $.error( 'Method ' +  method + ' does not exist on jQuery.fileInput' );
    }

  }

})(jQuery);
